import React, {useContext, useEffect} from 'react'
import StepBonusUI from './StepBonus'
import {AppContext} from '../../../contexts/AppContext'
import moment from "moment";

export default function StepBonus() {
  const [state, setState] = useContext(AppContext)

  useEffect(() => {
    const inpsServices = state.inpsServices;
    const step2InpsServiceId = state.step2InpsServiceId;
    if (inpsServices && inpsServices.length > 0 && !step2InpsServiceId) {
      //controllo se esiste un elemento che ha 'code' uguale a $serviceCode
      let noComISEE;

      if (state.division === '1') {
        noComISEE = inpsServices.find(service => service.code === '2S-NNCOM')
      } else {
        noComISEE = inpsServices.find(service => service.code === '2S-NNCOM(copy)')
        if (!noComISEE) {
            noComISEE = inpsServices.find(service => service.code === '1S-NNCOM')
        }
      }

      if (noComISEE && noComISEE.value) {
        //se esiste, lo imposto come valore di default per il campo 'step2InpsServiceId'
        setState(state => ({...state, step2InpsServiceId: noComISEE.value}))
      }

    }
  }, [state.inpsServices]);

  const handleEventChange = label => event => {

    if(label === 'variablePracticeInpsNumber'){
      setState(state => ({...state, fixedPracticeInpsNumber: `EIS${moment().year()}`}));
    }
    const {value} = event.target
    setState(state => ({...state, [label]: value}))
    // console.info(state)
  }
  const handleSimpleCheckboxChange = label => event => {
    const {checked} = event.target
    setState(state => ({...state, [label]: checked}))
  }
  const handleCheckboxChange = label => event => {
    const {checked, value} = event.target
    setState(state => ({...state, [label]: checked ? value : null}))
  }
  const handleBonusRequest = bonusType => event => {
    const {checked} = event.target
    const {step2bonuses} = state
    if (checked) {
      const selectedBonus = step2bonuses.find(
        item => item.bonustype && item.bonustype === bonusType
      )
      setState(state => ({
        ...state,
        step2BonusRequested: bonusType,
        step2BonusType: bonusType,
        step2BonusId: selectedBonus ? selectedBonus.value : null,
      }))
    } else {
      setState(state => ({
        ...state,
        step2BonusRequested: null,
        step2BonusType: bonusType,
        step2BonusId: '',
      }))
    }
  }

  return (
    <StepBonusUI
      state={state}
      onEventChange={handleEventChange}
      onSimpleCheckboxChange={handleSimpleCheckboxChange}
      onBonusRequest={handleBonusRequest}
      onCheckboxChange={handleCheckboxChange}
    />
  )
}
