import axios from 'axios'
import getErrors from '../../commonUtils/errors'
import moment from 'moment'
import {getBaseUrl} from '../../commonUtils/api'

const http = axios.create({
    baseURL: getBaseUrl() + '/api',
})

export const dateToServerString = date => {
    const mDate = moment(date)
    if (date && mDate.isValid()) {
        return mDate.format('YYYY-MM-DD')
    } else {
        return null
    }
}

//It returns the html text to show in the Notice banner (step 1), depending on division
export const getBookingFormNotice = async division =>
    await http.get(`formdocenti/notice/${division}`)

export async function getPackageGroups(destination, division) {
    const {data} = await http.post('formdocenti/destinations/packages', {
        destination,
        division,
    })
    return data
}



export async function createPayPalOrder(practice, amount) {
    const body = {practice, amount}
    const {data} = await http.post('payments/paypal/order/create', body)
    return data
}


export async function capturePayPalOrder(practice,orderID) {
    const body = {practice, orderID}
    const {data} = await http.post('payments/paypal/order/capture', body)
    return data
}


export async function getFormTurns(webgroup, division) {
    const {data} = await http.post('formdocenti/turns', {webgroup, division})
    return data
}

export async function getDepartureServices(id, division) {
    const {data} = await http.post('formdocenti/package/cities', {
        package: id,
        division: division,
    })
    return data
}

export function getStructures(id, division) {
    return http
        .post('formdocenti/package/structures', {package: id, division: division})
        .then(response => response.data)
}

export async function getNations(division) {
    const {data} = await http.get(`formdocenti/destinations/${division}`)
    return data
}

export function getPackage(id, division) {
    return http
        .get(`formdocenti/package/${id}/${division}`)
        .then(response => response.data)
}

export async function getComuniByName(input = '') {
    let response = await http
        .get(`formdocenti/comuni?q=${input}`)
        .then(response => response.data)

    return response.results
        ? response.results.map(item => {
            return {...item, value: item.id, label: item.name}
        })
        : []
}

export async function getComuniExactByName(input = '') {
    const response = await getComuniByName(input)
    return response.filter(
        item =>
            input &&
            item.name &&
            item.name.toLocaleLowerCase() === input.toLocaleLowerCase()
    )
}

export function getAlternativeServices(pack, alternative, division) {
    let body = {package: pack, alternative: alternative, division: division}
    return http
        .post('formdocenti/package/alternative-services', body)
        .then(response => response.data)
}

export async function getFiscalCode({birthDate, ...body}) {
    const {data} = await http.post('formdocenti/customers/fiscal-code', {
        birthDate: dateToServerString(birthDate),
        ...body,
    })
    return data
}

export async function getExtraServices(pack, alternative, division) {
    let body = {
        package: pack,
        alternative,
        division,
    }
    const {data} = await http.post('formdocenti/package/extra-services', body)
    return data
}

export async function registerIntent(state, token) {
    const purchaseintent = {
        id: state.id,
        division: state.division,
        authCode: state.id ? state.authCode : null,
        currentStep: state.activeStep + 1,
        step1Nation: state.step1Nation,
        step1PackageGroup: state.step1PackageGroup,
        step1Package: state.step1Package ? state.step1Package : null,
        step1Turn: state.step1Turn,
        step1DepartureServiceItems: state.step1DepartureServiceItems,
        step1DepartureServiceIds: state.step1DepartureServiceIds,
        step1Structure: state.step1Structure,
        step1SameResidence: state.step1SameResidence,
        step1ChildDocType: state.step1ChildDocType,
        step1ChildDocNumber: state.step1ChildDocNumber,
        step1ChildDocCountry: state.step1ChildDocCountry,
        step1ChildDocEmission: state.step1ChildDocEmission,
        step1ChildDocExpiration: state.step1ChildDocExpiration,
        step1HasStructure: !!state.step1HasStructure,
        step1ParentSurname: state.step1ParentSurname,
        step1ParentName: state.step1ParentName,
        step1ParentNationality: state.step1ParentNationality,
        step1ParentBirthCity: state.step1ParentBirthCity
            ? state.step1ParentBirthCity.name
            : null,
        step1ParentBirthProv: state.step1ParentBirthProv,
        step1ParentBirthDate: dateToServerString(state.step1ParentBirthDate),
        step1ParentGender: state.step1ParentGender,
        step1ParentFiscalCode: state.step1ParentFiscalCode,
        step1ParentStreetAddress: state.step1ParentStreetAddress,
        step1ParentResidenceCity: state.step1ParentResidenceCity,
        step1ParentCAP: state.step1ParentCAP,
        step1ParentProv: state.step1ParentProv,
        step1ParentEmail: state.step1ParentEmail,
        step1ParentPhone: state.step1ParentPhone,
        step1ParentPhoneAlt: state.step1ParentPhoneAlt,
        step1ParentBirthNation: state.step1ParentBirthNation,
        step1ParentForeignBirthCity: state.step1ParentForeignBirthCity,
        step1ChildBirthNation: state.step1ChildBirthNation,
        step1ChildForeignBirthCity: state.step1ChildForeignBirthCity,
        step1ChildSurname: state.step1ChildSurname,
        step1ChildName: state.step1ChildName,
        step1ChildNationality: state.step1ChildNationality,
        step1ChildBirthCity: state.step1ChildBirthCity
            ? state.step1ChildBirthCity.name
            : null,
        step1ChildBirthProv: state.step1ChildBirthProv,
        step1ChildBirthDate: dateToServerString(state.step1ChildBirthDate),
        step1ChildGender: state.step1ChildGender,
        step1ChildFiscalCode: state.step1ChildFiscalCode,
        step1ChildStreetAddress: state.step1ChildStreetAddress,
        step1ChildResidenceCity: state.step1ChildResidenceCity,
        step1ChildCAP: state.step1ChildCAP,
        step1ChildProv: state.step1ChildProv,
        step1ChildEmail: state.step1ChildEmail,
        step1ChildPhone: state.step1ChildPhone,
        step1ChildPhoneAlt: state.step1ChildPhoneAlt,
        step12017Question: state.step12017Question,
        step12018Question: state.step12018Question,
        step12019Question: state.step12019Question,
        step12020Question: state.step12020Question,
        step12021Question: state.step12021Question,
        step1NeverQuestion: state.step1NeverQuestion,
        step1Confirmed: state.activeStep === 0,
        step1Time: state.step1Time,
        step2Participation: state.step2Participation,
        step2InpsContributeQuantity: state.step2InpsContributeQuantity,
        step2InpsServiceId: state.step2InpsServiceId,
        step2Grade: state.step2Grade,
        step2Class: state.step2Class,
        step2BonusType: state.step2BonusType,
        step2Coupon: state.step2Coupon,
        step2BonusId: state.step2BonusId,
        step2BonusRequested: state.step2BonusRequested,
        step2BonusSurname: state.step2BonusSurname,
        step2BonusName: state.step2BonusName,
        step2AziendaConvenzionataNote: state.step2AziendaConvenzionataNote,
        step2Confirmed: state.activeStep === 1,
        step2Time: state.step2Time,
        step3Handicap: state.step3Handicap === 'si',
        step3DisabilitiesType: state.step3DisabilitiesType,
        step3DisabilitiesHelperType: state.step3DisabilitiesHelperType,
        step3DisabilitiesHelper: state.step3DisabilitiesHelper,
        step3CodiceDocente: state.step3CodiceDocente
            ? state.step3CodiceDocente.value
            : null, //
        step3DisabilitiesHelperSameRoom:
            state.step3DisabilitiesHelperSameRoom === 'si',
        // step3DisabilitiesNotes: state.step3DisabilitiesNotes,
        // step3Helper: state.step3Helper === 'si',
        // step3TypeHelpers: state.step3TypeHelpers,
        step3Intolerance: state.step3Intolerance === 'si',
        step3IntoleranceText: state.step3IntoleranceText,
        step3IntoleranceType: state.step3IntoleranceType,
        // step3MedicalCert: state.step3MedicalCert === 'si',
        step3NoteRoommate: state.step3NoteRoommate,
        step3RoommateSurname: state.step3RoommateSurname,
        step3RoommateName: state.step3RoommateName,
        step3NoteLeaving: decorateNotesWithHandicap(state, state.step3NoteLeaving),
        step3TeacherFullName: state.step3TeacherFullName,
        step3Disabilities: state.step3Disabilities,
        step3NumberHelpers: state.step3NumberHelpers,
        step3Confirmed: state.activeStep === 2,
        step3Time: state.step3Time,
        step4Nation: state.step4Nation,
        step4PackageGroup: state.step4PackageGroup,
        step4Turn: state.step4Turn,
        step4Package: state.step4Package ? state.step4Package : null,
        step4DepartureServiceItems: state.step4DepartureServiceItems,
        step4DepartureServiceIds: state.step4DepartureServiceIds,
        step4Structure: state.step4Structure,
        step4Privacy: state.step4Privacy,
        step4ExtraServices: state.step4ExtraServices
            ? state.step4ExtraServices.join()
            : null,
        step4Marketing: state.step4Marketing === 'si',
        step4Profilation: state.step4Profilation === 'si',
        step4Profilation2: state.step4Profilation2 === 'si',
        step4Exclusivity: state.step4Exclusivity,
        step4Confirmed: state.activeStep === 3,
        step4Time: state.step4Time,
        step5NewReservation: state.step5NewReservation,
        step2VoucherCodes: state.step2VoucherCodes || null,
        step2VoucherDiscountService: state.step2VoucherDiscountService || null,
        step2GiftCardDiscountService: state.step2GiftCardDiscountService || null,
        step2CodicePromoDiscountService: state.step2CodicePromoDiscountService || null,
        // step2VoucherDiscountAmount: state.voucherData
        //   ? state.voucherData.discountValue
        //   : null,
        step2INPSnewQuestion: state.step2INPSnewQuestion || null,
    }

    try {
        const config = {}
        if (token) {
            config.headers = {
                Authorization: `Bearer ${token}`,
            }
        }
        const body = {purchase_intent: purchaseintent}
        if (state.id && state.authCode) {
            // step > 0
            if (token && state.originPracticeId) {
                // case precompiled
                const {data} = await http.put(
                    `member/purchaseIntent/${state.id}/update`,
                    body,
                    config
                )
                return {result: true, data}
            }
            // normal case
            const {data} = await http.put(
                `formdocenti/purchaseintent/${state.id}`,
                body,
                config
            )
            return {result: true, data}
        } else {
            // step 0
            if (token && state.originPracticeId) {
                // case precompiled
                const {data} = await http.post(
                    `member/practice/${state.originPracticeId}/saveNew`,
                    body,
                    config
                )
                return {result: true, data}
            }
            // normal case
            const {data} = await http.post('formdocenti/purchaseintent', body, config)
            return {result: true, data}
        }
    } catch (e) {
        console.error(e.response)
        const response = e.response
        const genericError = {
            result: false,
            data: 'Errore non previsto, si prega di riprovare in seguito.',
        }
        if (!response) {
            return genericError
        }

        switch (response.status) {
            // case 500:
            //   if (response && response.data && response.data.error && response.data.error.exception && response.data.error.exception.length > 0) {
            //     return {
            //       result: false,
            //       data: response.data.error.exception[0].message
            //     };
            //   } else {
            //     return genericError;
            //   }
            case 400:
                return {
                    result: false,
                    data: getErrors(response.data.errors),
                }
            default:
                return genericError
        }
    }
}

export const userExistsByFiscalCode = async (fiscalCode) => {
    const {data} = await http.post('member/check-existence', {fiscalCode: fiscalCode})
    return data
}

export async function getCurrentPrice(params) {
    const helperNumber =
        params.helperNumber === 'assistenza'
            ? 0
            : params.helperNumber === 'h1'
                ? 1
                : params.helperNumber === 'h2'
                    ? 2
                    : params.helperNumber === 'h3'
                        ? 3
                        : undefined

    const body = {
        packagePrice: params.step1Subtotal,
        INPSServices: params.step2InpsServiceId,
        extraServices: params.step4ExtraServices
            ? params.step4ExtraServices.join()
            : null,
        bonusServices: params.step2BonusId,
        division: params.division,
        helperNumber: helperNumber,
        helperType:
            params.helperNumber === 'assistenza' ? 'assistenza' : params.helperType,
        geo: params.geo,
        packageID: params.packageID,
        basicCoreClusterCode: params.basicCoreClusterCode,
        step1HasStructure: params.step1HasStructure,
        step1Structure: params.step1Structure,
        step1DepartureServiceIds: params.step1DepartureServiceIds,
        step2VoucherCodes: params.step2VoucherCodes,
        step1ParentFiscalCode: params.step1ParentFiscalCode,
        step2VoucherDiscountService: params.step2VoucherDiscountService,
        step2GiftCardDiscountService: params.step2GiftCardDiscountService,
        step2CodicePromoDiscountService: params.step2CodicePromoDiscountService,
        id: params.id,
    }
    const {data} = await http.post('formdocenti/currentpricewithdeposit', body)
    return data
}

export const getProgramTypologies = async () => []

// #region payments
// export async function getPaymentClientToken(practice) {
//   const body = {
//     practice,
//   }
//   const {data} = await http.post('payments/token', body)
//   return data
// }

export async function payPractice(
    bankTransfer = false,
    paypalTransfer = false,
    nonce,
    practice,
    division
) {
    const body = {
        // NB: il flag banktransfer è stato inspiegabilmente implementato come stringa sulle api
        banktransfer: `${bankTransfer}`,
        paypaltransfer: `${paypalTransfer}`,
        payment_method_nonce: nonce,
        division,
        practice,
    }
    try {
        // console.log('invio body:',body);
        const {data} = await http.post('payments/payPractice', body)
        return data
    } catch (error) {
        const message =
            error.response.data &&
            error.response.data.error &&
            error.response.data.error.exception &&
            error.response.data.error.exception.length > 0
                ? error.response.data.error.exception[0].message
                : 'Errore generico'
        //console.log(error.response.data.error.exception[0].message)
        throw new Error(message)
    }
}

export async function updatePaymentData(practice, partecipazione, tipo) {
    const body = {
        practice,
        partecipazione,
        tipo,
    }
    const {data} = await http.post('payments/updatePaymentData', body)
    return data
}

export async function paymentConfirm(practice) {
    const body = {practice}
    const {data} = await http.post('payments/confirm', body)
    return data
}

export async function getPaypalClientId(practice) {
    const body = {practice}
    const {data} = await http.post('payments/paypal/api', body)
    return data
}

export async function finalizePaypalOrder(practice, orderID) {
    const body = {practice, orderID}
    const {data} = await http.post('payments/paypal/finalize', body)
    return data
}

export async function markPaymentError(practice, error = {}) {
    let response
    if (typeof error !== 'string') {
        response = JSON.stringify(error)
    } else {
        response = error
    }
    const body = {
        practice,
        response,
    }
    const {data} = await http.post('payments/paypal/markAsError', body)
    return data
}

// #endregion

// #region authentication
export const signIn = async (email, password) => {
    const body = {
        username: email,
        password,
    }
    const {data} = await http.post('member/login', body)
    return data
}

export const getUserByToken = async token => {
    const headers = {Authorization: `Bearer ${token}`}

    const {data} = await http.get('member/me', {headers})
    return data
}

export const signUp = async (email, password, userData) => {
    const body = {
        // firstName: userData.firstName,
        // lastName: userData.lastName,
        // birthPlace: userData.birthPlace,
        // birthNation: userData.birthNation,
        // foreignBirthCity: userData.foreignBirthCity,
        // birthDate: userData.birthDate,
        // fiscalCode: userData.fiscalCode,
        // mobilePhone: userData.mobilePhone,
        // privacyAccepted: userData.privacyAccepted,
        // marketingAccepted: userData.marketingAccepted,
        ...userData,
        email1: email,
        password,
        gender: userData.gender === 'F' ? 'F' : 'Z',
    }
    const {data} = await http.post('member/register', body)
    return data
}
// #endregion

// #region precompiled
export async function getPurchaseIntentFromPracticeId(token, practiceId) {
    const headers = {Authorization: `Bearer ${token}`}

    const {data} = await http.get(
        `member/practice/${practiceId}/purchaseIntent`,
        {headers}
    )
    return data
}

// #endregion

export async function verifyVoucher(voucherCodes, fiscalCode, division) {
    const {data} = await http.post('/formdocenti/voucher/check', {
        voucherCodes,
        fiscalCode,
        division
    })
    return data
}

export async function verifyDoubleBooking(
    fiscalCode,
    division,
    productDivision
) {
    const {data} = await http.post('/formdocenti/doublebooking/check', {
        fiscalCode,
        division,
        productDivision,
    })

    return data.doubleBooking
}

function decorateNotesWithHandicap(state, notes) {
    if (state.step3Handicap !== 'si') {
        return notes
    }

    return `****INFO DISABILITÀ****
Note Aggiuntive: ${state.step3DisabilitiesNotes}
****FINE INFO DISABILITÀ****

${notes}
`
}
