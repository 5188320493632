import React from 'react'
import PropTypes from 'prop-types'
import {makeStyles} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Coupon from './Coupon'
import {bonusTypes} from '../../specificUtils/configs'

const useStyles = makeStyles(theme => ({
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 270,
    },
    checkboxRow: {
        display: 'flex',
        alignItems: 'flex-end',
        marginBottom: 16,
    },
}))

export default function StepBonusPrivate(props) {
    const {
        step2BonusType,
        step2BonusName,
        step2Coupon,
        onBonusRequest,
        onEventChange,
    } = props
    const classes = useStyles()
    return (
        <React.Fragment>
            <div className="box-light boxed">
                <Grid container spacing={3} className="panel">
                    <Grid item xs={12} sm={3} className="bonus-wrapper">
                        <Typography variant="h6">Bonus</Typography>
                        <img
                            src="https://giocamondo.it/step2_bonus.png"
                            height={100}
                            alt="bonus"
                        />
                    </Grid>
                    <Grid item xs={12} sm={9}>
                        <div className="light-box">
                        <Typography variant="h5">VIAGGI IN GRUPPO? OTTIENI UNO SCONTO!</Typography>
                            <p>
                                <strong>Più amici, più vantaggi:</strong>
                            </p>
                            <ul>
                                <li><strong>5 persone → 30€ di sconto a testa</strong></li>
                                <li><strong>10 persone → 50€ di sconto a testa</strong></li>
                                <li><strong>15 persone → 70€ di sconto a testa</strong></li>
                            </ul>
                            <p>Il capogruppo riceve un <strong>bonus speciale Anni d'Argento!</strong>
                                <br/><br/><i>(Seleziona per applicare lo sconto e invia l’elenco partecipanti a
                                    soggiorni@giocamondo.it entro 7
                                    giorni.)</i>
                            </p>
                            <Grid container className={classes.checkboxRow}>
                                <Grid item>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={step2BonusType === bonusTypes.GRUPPO}
                                                onChange={onBonusRequest(bonusTypes.GRUPPO)}
                                            />
                                        }
                                        label="Voglio usufruire del Bonus Gruppi"
                                    />
                                </Grid>
                                {step2BonusType === bonusTypes.GRUPPO && (
                                    <Grid item>
                                        <TextField
                                            id="cognome"
                                            label="Cognome e Nome Referente"
                                            className={classes.textField}
                                            value={step2BonusName}
                                            onChange={onEventChange('step2BonusName')}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        </div>

                        <div className="light-box">
                        <Typography variant="h5">PORTA UN AMICO E RISPARMIATE ENTRAMBI!</Typography>
                            <ol>
                                <li><strong>PER TE</strong> (INPS): <strong>50€ di sconto immediato</strong></li>
                                <li><strong>PER I TUOI AMICI</strong> (privati): <strong>100€ di sconto a
                                    camera</strong></li>
                            </ol>
                            <p>
                                <i>(Seleziona per applicare lo sconto e indica i nominativi al momento della
                                    prenotazione.)</i>
                            </p>

                                <Grid container className={classes.checkboxRow}>
                                <Grid item>
                                <FormControlLabel
                                    control={
                                    <Checkbox
                                        checked={step2BonusType === bonusTypes.AMICO}
                                        onChange={onBonusRequest(bonusTypes.AMICO)}
                                    />
                                    }
                                    label="Voglio usufruire del Bonus Porta un amico"
                                />
                                </Grid>
                                {step2BonusType === bonusTypes.AMICO && (
                                <Grid item>
                                    <TextField
                                    id="cognome"
                                    label="Cognome e Nome"
                                    className={classes.textField}
                                    value={step2BonusName}
                                    onChange={onEventChange('step2BonusName')}
                                    />
                                </Grid>
                                )}
                            </Grid>
                        </div>

                        <div className="light-box">
                            <Typography variant="h5">SCEGLI LA CAMERA DOPPIA E RISPARMIA!</Typography>
                            <ol>
                                <li>Sconto immediato di <strong>100€</strong></li>
                                <li>Nessun supplemento singola, <strong>risparmio fino a 500€</strong></li>
                                <li>In omaggio: <strong>gadget esclusivo Anni d’Argento</strong></li>
                            </ol>
                            <p>
                                <i>(Valido per INPS e privati – seleziona per applicare lo sconto!)</i>
                            </p>
                            <p>Non applicabile a coniugi e/o familiari appartenenti allo stesso nucleo del titolare della pratica.</p>

                            <Grid container className={classes.checkboxRow}>
                                <Grid item>
                                    <FormControlLabel
                                        control={
                                        <Checkbox
                                            checked={step2BonusType === bonusTypes.DOPPIA}
                                            onChange={onBonusRequest(bonusTypes.DOPPIA)}
                                        />
                                        }
                                        label="In doppia è meglio"
                                    />
                                </Grid>
                            </Grid>
                        </div>

                        <p><i>
                        Le promozioni non sono cumulabili tra di loro. In fase di prenotazione è obbligatorio
indicare a quale promozione si vuole aderire.</i>
                        </p>
                        
                    </Grid>
                </Grid>
            </div>
            <br/>
            <Coupon onChange={onEventChange('step2Coupon')} value={step2Coupon}/>
        </React.Fragment>
    )
}

StepBonusPrivate.propTypes = {
    division: PropTypes.string,
    step2bonuses: PropTypes.arrayOf(
        PropTypes.shape({
            bonustype: PropTypes.string.isRequired,
        })
    ),
    step2BonusRequested: PropTypes.bool,
    step2BonusType: PropTypes.any,
    step2BonusSurname: PropTypes.string,
    step2BonusName: PropTypes.string,
    step2Coupon: PropTypes.string,
    onBonusRequest: PropTypes.func.isRequired,
    onEventChange: PropTypes.func.isRequired,
}
