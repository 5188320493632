import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import clsx from "clsx";
import {MenuItem} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import React from "react";
import PropTypes from 'prop-types'

export default function ClassParticipantAge(props) {

    const {
        classes,
        division,
        step2ParticipantAge,
        step2Class,
        errors,
        onEventChange,
    } = props;

    return <Grid item xs={12}>
        <div className="box-light boxed">
            <Typography variant="h6">Età e Classe frequentata *</Typography>
            <TextField
                id="s2eta_partecipant"
                required
                label="Età del partecipante"
                error={errors && errors.validStep2Class ? 'Indicazione Età *' : null}
                className={classes.textField6}
                value={step2ParticipantAge}
                onChange={onEventChange('step2ParticipantAge')}
                //helperText=
                margin="normal"
            />
            {division === '1' ? (
                <TextField
                    id="s2classe-anno"
                    select
                    required
                    error={errors && errors.validStep2Class ? 'Indicazione Classe *' : null}
                    label="Classe frequentata nell'anno scolastico in corso"
                    className={clsx(classes.textField6, 'select ', 'col-xs-12')}
                    value={step2Class || ''}
                    onChange={onEventChange('step2Class')}
                    SelectProps={{
                        MenuProps: {
                            className: classes.menu,
                        },
                    }}
                    margin="normal">
                    <MenuItem value="1^ Superiore">1^ Superiore</MenuItem>
                    <MenuItem value="2^ Superiore">2^ Superiore</MenuItem>
                    <MenuItem value="3^ Superiore">3^ Superiore</MenuItem>
                    <MenuItem value="4^ Superiore">4^ Superiore</MenuItem>
                    <MenuItem value="5^ Superiore">5^ Superiore</MenuItem>
                </TextField>
            ) : (
                <TextField
                    id="s2classe-anno"
                    select
                    //required
                    error=''
                    label="Classe frequentata nell'anno scolastico in corso"
                    className={clsx(classes.textField6, 'select ', 'col-xs-12')}
                    value={step2Class || ''}
                    onChange={onEventChange('step2Class')}
                    SelectProps={{
                        MenuProps: {
                            className: classes.menu,
                        },
                    }}
                    margin="normal">
                    <MenuItem value="1^ Elementare">1^ Elementare</MenuItem>
                    <MenuItem value="2^ Elementare">2^ Elementare</MenuItem>
                    <MenuItem value="3^ Elementare">3^ Elementare</MenuItem>
                    <MenuItem value="4^ Elementare">4^ Elementare</MenuItem>
                    <MenuItem value="5^ Elementare">5^ Elementare</MenuItem>
                    <MenuItem value="1^ Media">1^ Media</MenuItem>
                    <MenuItem value="2^ Media">2^ Media</MenuItem>
                    <MenuItem value="3^ Media">3^ Media</MenuItem>
                </TextField>
            )}
        </div>
    </Grid>;
}

ClassParticipantAge.propTypes = {
    classes: PropTypes.object.isRequired,
    division: PropTypes.string.isRequired,
    step2ParticipantAge: PropTypes.string.isRequired,
    step2Class: PropTypes.string.isRequired,
    errors: PropTypes.object,
    onEventChange: PropTypes.func.isRequired,
}
