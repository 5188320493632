export function convertCountDown(timeInSeconds) {
    const hours = Math.floor(timeInSeconds / 3600);
    const minutes = Math.floor((timeInSeconds % 3600) / 60);
    const seconds = timeInSeconds % 60;

    return {hours, minutes, seconds};
}

export function calculatePeriodLabelFromNumberOfDays(numberOfDays) {
    let label;
    if (numberOfDays % 7 === 0) {
        const weeks = numberOfDays / 7;
        label = `${weeks} settiman${weeks > 1 ? 'e' : 'a'}`;
    } else {
        label = `${numberOfDays} giorn${numberOfDays > 1 ? 'i' : 'o'}`;
    }
    return label;
}

//20250325 - Impostato false perché in staging ci sono le chiavi PayPal di produzione. Rimuovere il false e il commento se si vuole il comportamento corretto
export const isInDebugMode = false; //process.env.NODE_ENV === 'development' || (window.location.hostname === 'shop.staging.giocamondo.it') || (window.location.hostname === 'shop-bis.staging.giocamondo.it');
