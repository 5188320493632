import React, {useState} from 'react'
import PropTypes from 'prop-types'

// material-ui components
import {makeStyles} from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import Checkbox from '@material-ui/core/Checkbox'
import FormLabel from '@material-ui/core/FormLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
// local components
import Anagrafica from '../../../components/Anagrafica'
import ResidenceAutocomplete from '../../../components/ResidenceAutocomplete'
import Membership from 'components/Membership'
import {participations, participationsLookup} from '../../specificUtils/configs'
import PackageSelectorWrapper from '../PackageSelectorWrapper'
import {birthNationalityType} from './utils'
import StepCustomerDataNotice from './StepCustomerDataNotice'
import {Button, MenuItem, Paper} from '@material-ui/core'
//import Payment from '../Payment.jsx'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import StepCustomerDialog from './StepCustomerDialog'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  textField12: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '100%',
  },
  textField11: {
    width: '91.666667%',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  textField10: {
    width: '83.333333%',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  textField8: {
    width: '62.666667%',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  textField6: {
    width: '46.666%',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  textField4: {
    width: '32.333333%',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  textField3: {
    width: '23.66666%',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  textField2: {
    width: '16.666667%',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
  button: {
    margin: theme.spacing(1),
  },
  errorMessage: {
    color: 'red',
  },
  warningBox: {
    width: '50%',
    background: '#fffae5',
    padding: 20,
    margin: '0 auto',
    minWidth: 200,
    color: 'red',
    textAlign: 'center',
    marginTop: 32,
    marginBottom: 32,
  },
  additionalParticipants: {
    backgroundColor: '#f7f8f9',
    padding: 20,
    marginTop: 20,
    marginBottom: 20,
  },
  participantBox: {
    paddingTop: 8,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  participationButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
}))

export default function StepCustomerData(props) {
  const classes = useStyles()
  const [multipleParticipants, setMultipleParticipants] = useState(null)
  const [participantsRemoved, setParticipantsRemoved] = useState(0)
  const [showParticipantsModal, setShowParticipantsModal] = useState(false)

  const {
    state,
    isUserLogged = false,
    // onEventChange,
    onValueChange,
    onParticipationChange,
    onBirthNationalityChange,
    onParentBirthCityChange,
    onParentResidenceCityChange,
    onPrivacyChange,
    onContributoInps,
    handleStep1ParticipantsChange,
  } = props

  //20 marzo 2024, aggiunto anche questo workaround qui dentro
  // const initialYear = 2023; //Impostare l'anno iniziale utilizzato per mostrare il box "Ho usufruito del contributo del Bando Estate INPSieme degli anni (opzionale):"
  const years = []; //Array.from({ length: new Date().getFullYear() - initialYear }, (v, i) => (initialYear + i).toString());
  //Aggiungo anche la checkbox "No"
  years.push('Sì');
  years.push('No');
  const errors = state.errors || {}
  return (
    <Grid container className={classes.root} spacing={2}>
      <Grid item xs={12}>
        <StepCustomerDataNotice division={state.division} />
      </Grid>
      <Grid item xs={12} className="box-well">
        <PackageSelectorWrapper title="Scegli il soggiorno" />
      </Grid>
      <Grid item xs={12} style={{marginBottom: '64px'}}>
        <Membership />
      </Grid>
      <Grid item xs={12}>
        <Grid container className="bordered-bottom">
          <Typography variant="h6">Tipologia di cliente</Typography>
        </Grid>

        <TextField
          id="standard-select-participation"
          select
          className={classes.textField4 + 'select col-xs-12 lateral-margin-8px'}
          value={state.step2Participation || ''}
          error={!!errors && !!errors.step2Participation}
          onChange={evt => onParticipationChange(evt.target.value)}
          SelectProps={{
            native: true,
            MenuProps: {
              className: classes.menu,
            },
          }}
          helperText="Seleziona tipo di partecipazione"
          margin="normal">
          <option value="">Seleziona</option>
          {participations
            .filter(
              el =>
                el.value === participationsLookup.PRIVATO ||
                //el.value === participationsLookup.INPSNUOVO
                el.value === participationsLookup.INPSIEME
            )
            .map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
        </TextField>
        <Anagrafica
          surname={state.step1ParentSurname}
          surnameError={errors.step1ParentSurname}
          handleSurnameChange={onValueChange('step1ParentSurname')}
          name={state.step1ParentName}
          nameError={errors.step1ParentName}
          handleNameChange={onValueChange('step1ParentName')}
          gender={state.step1ParentGender}
          genderError={errors.step1ParentGender}
          handleGenderChange={onValueChange('step1ParentGender')}
          nationality={state.step1ParentNationality}
          nationalityError={errors.step1ParentNationality}
          handleNationalityChange={onBirthNationalityChange(
            'step1ParentNationality',
            birthNationalityType.PARENT
          )}
          birthNation={state.step1ParentBirthNation}
          birthNationError={errors.step1ParentBirthNation}
          handleBirthNationChange={onValueChange('step1ParentBirthNation')}
          foreignBirthCity={state.step1ParentForeignBirthCity}
          foreignBirthCityError={errors.step1ParentForeignBirthCity}
          handleForeignBirthCityChange={onValueChange(
            'step1ParentForeignBirthCity'
          )}
          birthCity={state.step1ParentBirthCity}
          birthCityError={errors.step1ParentBirthCity}
          handleBirthCityChange={onParentBirthCityChange}
          birthProvince={state.step1ParentBirthProv}
          birthProvinceError={errors.step1ParentBirthProv}
          handleBirthProvinceChange={onValueChange('step1ParentBirthProv')}
          birthDate={state.step1ParentBirthDate}
          birthDateError={errors.step1ParentBirthDate}
          handleBirthDateChange={onValueChange('step1ParentBirthDate')}
          fiscalCode={state.step1ParentFiscalCode}
          fiscalCodeError={errors.step1ParentFiscalCode}
          handleFiscalCodeChange={onValueChange('step1ParentFiscalCode')}
          fiscalCodeDesc={
            isUserLogged
              ? ''
              : 'Attenzione ricontrolla il codice fiscale che sarà utilizzato in tutti i documenti a te intestati'
          }
          disableFiscalCode={isUserLogged}
          email={state.step1ParentEmail}
          emailError={errors.step1ParentEmail}
          emailRequired
          handleEmailChange={onValueChange('step1ParentEmail')}
          phone={state.step1ParentPhoneAlt}
          phoneError={errors.step1ParentPhoneAlt}
          //phoneRequired
          handlePhoneChange={onValueChange('step1ParentPhoneAlt')}
          cellPhone={state.step1ParentPhone}
          cellPhoneError={errors.step1ParentPhone}
          cellPhoneRequired
          handleCellPhoneChange={onValueChange('step1ParentPhone')}
          cellPhoneDesc="Inserire numero di cellulare per ricevere comunicazioni importanti relative alla pratica"
        />

        <Grid container>
          <Typography variant="h6">Residenza</Typography>
        </Grid>

        <ResidenceAutocomplete
          styles={classes}
          streetAddress={state.step1ParentStreetAddress}
          streetAddressError={errors && errors.step1ParentStreetAddress}
          onStreetAddressChange={onValueChange('step1ParentStreetAddress')}
          city={state.step1ParentResidenceCityObj}
          cityError={errors && errors.step1ParentResidenceCity}
          onCityDelete={() => onParentResidenceCityChange(null)}
          onCitySelect={onParentResidenceCityChange}
          cap={state.step1ParentCAP}
          capError={errors && errors.step1ParentCAP}
          onCapChange={onValueChange('step1ParentCAP')}
          province={state.step1ParentProv}
          provinceError={errors && errors.step1ParentProv}
          onProvinceChange={onValueChange('step1ParentProv')}
          onRegionChange={onValueChange('step1ParentRegion')}
        />
        <br />

        {/*
        <Grid container>
          <Typography variant="h6">Recapito di emergenza</Typography>
        </Grid>

        <Grid container spacing={props.spacing || 2} alignItems="flex-start">
          <Grid item xs={12} sm={6} md={4}>
            <TextField
                label="Nome e Cognome *"
                onChange={onValueChange('step1IceFullName')}
                value={state.step1IceFullName}
                error={!!errors.step1IceFullName}
                margin="normal"
                fullWidth
                helperText={!!errors.step1IceFullName && 'Nome e Cognome contatto di emergenza richiesto'}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
                label="Cellulare *"
                onChange={onValueChange('step1IceCellPhone')}
                value={state.step1IceCellPhone}
                error={!!errors.step1IceCellPhone}
                margin="normal"
                fullWidth
                helperText={
                  !!errors.step1IceCellPhone ? ((typeof errors.step1IceCellPhone === 'string') ? errors.step1IceCellPhone : 'Cellulare di emergenza richiesto') : 'Inserire numero di cellulare del recapito di emergenza' || null
                }
            />
          </Grid>
        </Grid>
        <br />
        */}

      </Grid>

      <Grid item xs={12}>
        <Grid container className="bordered-bottom">
          <Typography variant="h6">Partecipanti aggiuntivi</Typography>
        </Grid>

        <Grid container className={classes.additionalParticipants}>
          <Grid item md={6} xs={12}>
            <Typography>
              Ci sono altri partecipanti oltre al titolare?
            </Typography>
            <RadioGroup
              aria-label="gender"
              name="gender1"
              value={multipleParticipants}
              row
              onChange={({target}) => {
                setMultipleParticipants(target.value)
                if (target.value === 'false') {
                  handleStep1ParticipantsChange([])
                }
              }}>
              <FormControlLabel value={'true'} control={<Radio />} label="Si" />
              <FormControlLabel
                value={'false'}
                control={<Radio />}
                label="No"
              />
            </RadioGroup>
          </Grid>
          <Grid item md={6} xs={12}>
            {multipleParticipants === 'true' && (
              <>
                <Typography>
                  Indicaci il numero dei partecipanti aggiuntivi
                </Typography>
                <TextField
                  select
                  fullWidth
                  onChange={({target}) => {
                    const additionalParticipantsNumber =
                      state.step1Participants.length
                    if (additionalParticipantsNumber === 0) {
                      return handleStep1ParticipantsChange(
                        new Array(target.value).fill(undefined)
                      )
                    }
                    if (target.value > additionalParticipantsNumber) {
                      setShowParticipantsModal(true)
                      setParticipantsRemoved(0)

                      const difference =
                        target.value - additionalParticipantsNumber
                      return handleStep1ParticipantsChange([
                        ...state.step1Participants,
                        ...new Array(difference).fill(undefined),
                      ])
                    }

                    const difference =
                      additionalParticipantsNumber - target.value
                    setParticipantsRemoved(difference)
                    setShowParticipantsModal(true)

                    const newParticipants = state.step1Participants.slice(
                      0,
                      target.value
                    )

                    handleStep1ParticipantsChange(newParticipants)
                  }}>
                  {[...Array(5)].map((_, i) => (
                    <MenuItem value={i + 1}>{i + 1}</MenuItem>
                  ))}
                </TextField>
              </>
            )}
          </Grid>
        </Grid>

        {state.step1Participants.map((_, i) => {
          const errorsArray = errors.step1Participants
          const participantErrors = errorsArray ? errorsArray[i] || {} : {}

          const additionalParticipant = state.step1Participants[i] || {}

          const handleChange = name => value => {
            const step1Participants = [...state.step1Participants]
            if (!step1Participants[i]) {
              step1Participants[i] = {nationality: 'Italiana'}
            }

            step1Participants[i][name] = value
            handleStep1ParticipantsChange(step1Participants)
          }

          const handleBirthNationalityChange = value => {
            const step1Participants = [...state.step1Participants]

            if (!step1Participants[i]) {
              step1Participants[i] = {nationality: 'Italiana'}
            }

            step1Participants[i].nationality = value
            step1Participants[i].birthCity = ''
            step1Participants[i].birthProv = ''
            step1Participants[i].birthNation = ''
            step1Participants[i].foreignBirthCity = ''
            handleStep1ParticipantsChange(step1Participants)
          }

          const handleChildBirthCityChange = value => {
            const step1Participants = [...state.step1Participants]
            if (!step1Participants[i]) {
              step1Participants[i] = {nationality: 'Italiana'}
            }

            step1Participants[i].birthCity = value
            step1Participants[i].birthProv = value.car

            handleStep1ParticipantsChange(step1Participants)
          }
          const handleRelationChange = value => {
            const step1Participants = [...state.step1Participants]

            if (!step1Participants[i]) {
              step1Participants[i] = {nationality: 'Italiana'}
            }

            step1Participants[i].relation = value

            handleStep1ParticipantsChange(step1Participants)
          }
          return (
            <Paper className="participant-form-container" key={i}>
              <Grid
                container
                className={classes.participantBox + ' bordered-bottom'}>
                <Typography variant="h6">Partecipante #{i + 1}</Typography>
              </Grid>
              <TextField
                select
                value={additionalParticipant.relation || ''}
                label="Relazione con Titolare pratica"
                style={{width: 280}}
                onChange={({target}) => handleRelationChange(target.value)}>
                <MenuItem value="genitore">Genitore</MenuItem>
                <MenuItem value="figlio">Figlio</MenuItem>
                <MenuItem value="fratello">Fratello</MenuItem>
                <MenuItem value="compagno">Compagno</MenuItem>
                <MenuItem value="coniuge">Coniuge</MenuItem>
                <MenuItem value="assistente">Assistente</MenuItem>
                <MenuItem value="altro">Altro</MenuItem>
              </TextField>

              <Anagrafica
                spacing={1}
                size="small"
                surname={additionalParticipant['surname']}
                surnameError={participantErrors.surname}
                handleSurnameChange={handleChange('surname')}
                name={additionalParticipant['name']}
                nameError={participantErrors.name}
                handleNameChange={handleChange('name')}
                gender={additionalParticipant['gender']}
                genderError={participantErrors.gender}
                handleGenderChange={handleChange('gender')}
                nationality={additionalParticipant['nationality']}
                // nationalityError={participantErrors.nationality}
                handleNationalityChange={handleBirthNationalityChange}
                birthNation={additionalParticipant['birthNation']}
                birthNationError={participantErrors.birthNation}
                handleBirthNationChange={handleChange('birthNation')}
                foreignBirthCity={additionalParticipant['foreignBirthCity']}
                foreignBirthCityError={participantErrors.foreignBirthCity}
                handleForeignBirthCityChange={handleChange('foreignBirthCity')}
                birthCity={additionalParticipant['birthCity']}
                birthCityError={participantErrors.birthCity}
                handleBirthCityChange={handleChildBirthCityChange}
                birthProvince={additionalParticipant['birthProv']}
                birthProvinceError={participantErrors.birthProv}
                handleBirthProvinceChange={handleChange('birthProv')}
                birthDate={additionalParticipant['birthDate']}
                birthDateError={participantErrors.birthDate}
                handleBirthDateChange={handleChange('birthDate')}
                fiscalCode={additionalParticipant['fiscalCode']}
                fiscalCodeError={participantErrors.fiscalCode}
                handleFiscalCodeChange={handleChange('fiscalCode')}
                fiscalCodeDesc="Attenzione ricontrolla il codice fiscale del partecipante, che sarà utilizzato in tutti i documenti a lui intestati"
                /*
                ** Richiesta rimozione campi Partecipante - feb 2025
                
                email={additionalParticipant['email']}
                emailError={participantErrors.email}
                handleEmailChange={handleChange('email')}
                cellPhone={additionalParticipant['phone']}
                cellPhoneError={participantErrors.phone}
                handleCellPhoneChange={handleChange('phone')}
                phone={additionalParticipant['phoneAlt']}
                phoneError={participantErrors.phoneAlt}
                handlePhoneChange={handleChange('phoneAlt')}
                */
              />

              {/* <Typography variant="h6" style={{paddingTop: 16}}>
                  Residenza del partecipante
                </Typography>

                <ResidenceAutocomplete
                  styles={classes}
                  streetAddress={state.step1ParentStreetAddress}
                  streetAddressError={errors && errors.step1ParentStreetAddress}
                  onStreetAddressChange={onValueChange(
                    'step1ParentStreetAddress'
                  )}
                  city={state.step1ParentResidenceCityObj}
                  cityError={errors && errors.step1ParentResidenceCity}
                  onCityDelete={() => onParentResidenceCityChange(null)}
                  onCitySelect={onParentResidenceCityChange}
                  cap={state.step1ParentCAP}
                  capError={errors && errors.step1ParentCAP}
                  onCapChange={onValueChange('step1ParentCAP')}
                  province={state.step1ParentProv}
                  provinceError={errors && errors.step1ParentProv}
                  onProvinceChange={onValueChange('step1ParentProv')}
                  onRegionChange={onValueChange('step1ParentRegion')}
                /> */}
            </Paper>
          )
        })}

        <br />
        <Grid container>
          <br />
          <Grid container spacing={2} className="box-pp">
            {(state.step2Participation === participationsLookup.INPSIEME ||
                state.step2Participation === participationsLookup.INPSNUOVO) && (
                <Grid item xs={12}>
                  <div className="alert alert-primary" role="alert">
                    <FormControl
                        component="fieldset"
                        className={classes.formControl}
                        error={errors && !!errors.step1Questions}
                    >
                      {errors && errors.step1Questions &&  <FormHelperText>
                        {errors.step1Questions}
                      </FormHelperText>}
                      <FormLabel component="legend">
                        Nel 2023 e/o 2024 sei risultato vincitore del contributo INPS?
                      </FormLabel>
                      <FormGroup name="partecipant" className={classes.group}>
                        {years.map(year => {
                          return <FormControlLabel
                              control={
                                <Checkbox
                                    checked={state.step1Questions.includes(year)}
                                    onChange={onContributoInps}
                                    value={year}
                                />
                              }
                              label={year}
                          />
                        })}
                      </FormGroup>
                    </FormControl>
                  </div>
                </Grid>
            )}
            <Grid item xs={12}>
              <p>
                <small>
                  Informativa estesa sulla
                  {state.division === '1' ? (
                    <a
                      href="https://www.iubenda.com/privacy-policy/656885"
                      title="Informativa privacy Giocamondo Study"
                      rel="noopener noreferrer"
                      target="_blank">
                      {' '}
                      privacy
                    </a>
                  ) : (
                    <a
                      href="https://www.iubenda.com/privacy-policy/411930"
                      title="Informativa privacy La Mia Estate"
                      rel="noopener noreferrer"
                      target="_blank">
                      {' '}
                      privacy
                    </a>
                  )}
                  . Ai sensi e per gli effetti dell'art.4 DPR 445/2000, in
                  relazione al rilascio di dichiarazioni mendaci, dichiaro che
                  le informazioni rese nel presente form corrispondono a verità
                  e accetto che queste vengano utilizzate unicamente per le
                  finalità per le quali sono state acquisite (GDPR - Regolamento 2016/679). Per quanto riguarda i consensi da lei
                  sottoscritti le comunichiamo che limiteremo le nostre attività
                  sui vostri dati, per le finalità indicate nell’informativa,
                  entro i limiti delle autorizzazioni che ci avete concesso e al
                  fine di verificare i consensi sottoscritti può inviare una
                  mail al seguente indirizzo:
                  {state.division === '1' ? (
                    <a
                      href="mailto:privacy@giocamondostudy.it"
                      title="Mail per verificare i consensi sottoscritti">
                      privacy@giocamondostudy.it
                    </a>
                  ) : (
                    <a
                      href="mailto:privacy@giocamondo.it"
                      title="Mail per verificare i consensi sottoscritti">
                      privacy@giocamondo.it
                    </a>
                  )}
                  . L'accettazione è obbligatoria per poter procedere all'invio
                  dei dati.
                </small>
                <br />
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={isUserLogged}
                      checked={state.step4Privacy}
                      onChange={evt => onPrivacyChange(evt.target.checked)}
                    />
                  }
                  label="Dichiaro di aver preso visione della privacy policy"
                />
              </p>
            </Grid>

            <Grid item xs={12}>
              <p>
                <small>
                  Consenti il trattamento dei Tuoi dati personali per il
                  ricevimento della Newsletter aziendale, di comunicazioni
                  relative a nostri servizi, prodotti, promozioni per finalità
                  di marketing diretto?
                  {state.division === '1' ? (
                    <a
                      href="https://www.iubenda.com/privacy-policy/656885"
                      title="Informativa privacy Giocamondo Study"
                      rel="noopener noreferrer"
                      target="_blank">
                      {' '}
                      Leggi qui{' '}
                    </a>
                  ) : (
                    <a
                      href="https://www.iubenda.com/privacy-policy/411930"
                      title="Informativa privacy La Mia Estate"
                      rel="noopener noreferrer"
                      target="_blank">
                      {' '}
                      Leggi qui{' '}
                    </a>
                  )}
                  l'informativa estesa sulla privacy per maggiori dettagli.
                </small>
              </p>
              <RadioGroup
                aria-label="position"
                name="position"
                value={state.step4Marketing}
                disabled={isUserLogged}
                onChange={onValueChange('step4Marketing')}
                row>
                <FormControlLabel
                  value="si"
                  disabled={isUserLogged}
                  control={<Radio color="primary" />}
                  label="Acconsento"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="no"
                  disabled={isUserLogged}
                  control={<Radio color="primary" />}
                  label="Non Acconsento"
                  labelPlacement="end"
                />
              </RadioGroup>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <StepCustomerDialog
        title="Modifica numero partecipanti"
        open={showParticipantsModal}
        handleClose={() => setShowParticipantsModal(false)}>
        {participantsRemoved
          ? `${
              participantsRemoved === 1
                ? "L'ultimo partecipante sarà rimosso"
                : `Gli ultimi ${participantsRemoved} partecipanti saranno rimossi`
            } `
          : 'Inserire i dati anagrafici dei nuovi partecipanti'}
      </StepCustomerDialog>
    </Grid>
  )
}

StepCustomerData.propTypes = {
  state: PropTypes.object.isRequired,
  isUserLogged: PropTypes.bool,
  onEventChange: PropTypes.func.isRequired,
  onValueChange: PropTypes.func.isRequired,
  onParticipationChange: PropTypes.func.isRequired,
  onBirthNationalityChange: PropTypes.func.isRequired,
  onParentBirthCityChange: PropTypes.func.isRequired,
  onChildBirthCityChange: PropTypes.func.isRequired,
  onParentResidenceCityChange: PropTypes.func.isRequired,
  onChildResidenceCityChange: PropTypes.func.isRequired,
  onSameResidenceChange: PropTypes.func.isRequired,
  onPrivacyChange: PropTypes.func.isRequired,
  onContributoInps: PropTypes.func.isRequired,
}
