import React from 'react'
import PropTypes from 'prop-types'
import {makeStyles} from '@material-ui/core/styles'
import clsx from 'clsx'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import {
    bonusTypes,
    participationsLookup,
} from '../../specificUtils/configs'
import Coupon from './Coupon'

const useStyles = makeStyles(theme => ({
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 270,
    },
    textField4: {
        width: '32.333333%',
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    menu: {
        width: 200,
    },
    checkboxRow: {
        display: 'flex',
        alignItems: 'flex-end',
        marginBottom: 16,
    },
}))

export default function StepBonusInpsieme(props) {
    const {
        // division,
        inpsServices,
        step2Participation,
        step2InpsServiceId,
        step2BonusName,
        onEventChange,
        onBonusRequest,
        step2Coupon,
        step2BonusType,
        variablePracticeInpsNumber,
    } = props
    const errors = props.errors || {}
    const classes = useStyles()
    const isNewInps = step2Participation === participationsLookup.INPSNUOVO
    const isInps = step2Participation === participationsLookup.INPSIEME


    //const title = isNewInps
    const title = isInps
        ? 'Bando INPSieme 2025'
        : 'Bando INPSieme 2025'

    //const description = isNewInps
    const description = isInps
        ? "Indica l'ISEE 2025 (domanda posta per fini statistici)"
        : "Indica l'ISEE 2025 (domanda posta per fini statistici)"


    return (
        <React.Fragment>
            {/*
            ** Richiesta rimozione Feb 2025

            <div className="box-light boxed">
                <Typography variant="h6">{title}</Typography>
                <Typography variant="body1">{description}</Typography>

                <TextField
                    id="inps-contributo"
                    select
                    error={
                        errors && errors.step2InpsServiceId
                            ? 'Indicazione contributo obbligatoria *'
                            : null
                    }
                    className={classes.textField4 + ' select col-xs-12'}
                    value={step2InpsServiceId}
                    onChange={onEventChange('step2InpsServiceId')}
                    SelectProps={{
                        native: true,
                        MenuProps: {
                            className: classes.menu,
                        },
                    }}
                    helperText="Indica l'ISEE 2024 (domanda posta per fini statistici)">
                    <option value="">Seleziona ISEE</option>
                    {inpsServices.map(option => (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </TextField>
            </div>
            */}
            <br/>
            <div className="box-light boxed">
                <Typography variant="h6">Numero pratica Bando INPS (sarà disponibile all'interno della tua area
                    riservata INPS solo dopo aver ricevuto comunicazione sull'idoneità della tua pratica)</Typography>
                <Typography variant="body1">Ti ricordiamo che, se non disponi di questo numero al momento della
                    prenotazione avrai la possibilità di inserirlo successivamente all'interno dell'Area Giocamondo
                    Club. Tale informazione sarà fondamentale per verificare il tuo posizionamento nella graduatoria e
                    gestire al meglio la tua pratica INPS.</Typography>
                <TextField
                    id="variablePracticeInpsNumber"
                    label="EIA2025__"
                    className={classes.textField6}
                    value={variablePracticeInpsNumber}
                    onChange={onEventChange('variablePracticeInpsNumber')}
                    helperText={errors && errors.variablePracticeInpsNumber && 'Il codice deve contenere solo numeri e lettere e dev\'essere formato da 15 caratteri'}
                    margin="normal"
                    error={errors && errors.variablePracticeInpsNumber}
                />
            </div>
            <br/>
            <div className="box-light boxed">
                <Grid
                    container
                    spacing={3}
                    className="panel"
                    style={{paddingRight: 16}}>
                    <Grid item xs={12} sm={3} className="bonus-wrapper">
                        <Typography variant="h6">Bonus</Typography>
                        <img
                            src="https://giocamondo.it/step2_bonus.png"
                            height={100}
                            alt="bonus"
                        />
                    </Grid>
                    <Grid item xs={12} sm={9}>
                        <div className="light-box">
                            <Typography variant="h5">VIAGGI IN GRUPPO? OTTIENI UNO SCONTO!</Typography>
                            <p>
                                <strong>Più amici, più vantaggi:</strong>
                            </p>
                            <ul>
                                <li><strong>5 persone → 30€ di sconto a testa</strong></li>
                                <li><strong>10 persone → 50€ di sconto a testa</strong></li>
                                <li><strong>15 persone → 70€ di sconto a testa</strong></li>
                            </ul>
                            <p>Il capogruppo riceve un <strong>bonus speciale Anni d'Argento!</strong>
                                <br/><br/><i>(Seleziona per applicare lo sconto e invia l’elenco partecipanti a
                                    soggiorni@giocamondo.it entro 7
                                    giorni.)</i></p>
                            <Grid container className={classes.checkboxRow}>
                                <Grid item>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={step2BonusType === bonusTypes.GRUPPO}
                                                onChange={onBonusRequest(bonusTypes.GRUPPO)}
                                            />
                                        }
                                        label="Voglio usufruire del Bonus Gruppi"
                                    />
                                </Grid>
                                {step2BonusType === bonusTypes.GRUPPO && (
                                    <Grid item>
                                        <TextField
                                            id="cognome"
                                            label="Cognome e Nome Referente"
                                            className={classes.textField}
                                            value={step2BonusName}
                                            onChange={onEventChange('step2BonusName')}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        </div>

                        <div className="light-box">
                            <Typography variant="h5">PORTA UN AMICO E RISPARMIATE ENTRAMBI!</Typography>
                            <ol>
                                <li><strong>PER TE</strong> (INPS): <strong>50€ di sconto immediato</strong></li>
                                <li><strong>PER I TUOI AMICI</strong> (privati): <strong>100€ di sconto a
                                    camera</strong></li>
                            </ol>
                            <p>
                                <i>(Seleziona per applicare lo sconto e indica i nominativi al momento della
                                    prenotazione.)</i>
                            </p>
                            <Grid container className={classes.checkboxRow}>
                                <Grid item>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={step2BonusType === bonusTypes.AMICO}
                                                onChange={onBonusRequest(bonusTypes.AMICO)}
                                            />
                                        }
                                        label="Voglio usufruire del Bonus Porta un amico"
                                    />
                                </Grid>
                                {step2BonusType === bonusTypes.AMICO && (
                                    <Grid item>
                                        <TextField
                                            id="cognome"
                                            label="Cognome e Nome"
                                            className={classes.textField}
                                            value={step2BonusName}
                                            onChange={onEventChange('step2BonusName')}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        </div>

                        <div className="light-box">
                            <Typography variant="h5">SCEGLI LA CAMERA DOPPIA E RISPARMIA!</Typography>
                            <ol>
                                <li>Sconto immediato di <strong>100€</strong></li>
                                <li>Nessun supplemento singola, <strong>risparmio fino a 500€</strong></li>
                                <li>In omaggio: <strong>gadget esclusivo Anni d’Argento</strong></li>
                            </ol>
                            <p>
                                <i>(Valido per INPS e privati – seleziona per applicare lo sconto!)</i>
                            </p>
                            <p>Non applicabile a coniugi e/o familiari appartenenti allo stesso nucleo del titolare della pratica.</p>
                            <Grid container className={classes.checkboxRow}>
                                <Grid item>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={step2BonusType === bonusTypes.DOPPIA}
                                                onChange={onBonusRequest(bonusTypes.DOPPIA)}
                                            />
                                        }
                                        label="In doppia è meglio"
                                    />
                                </Grid>
                            </Grid>
                        </div>

                        <p><i>
                            Le promozioni non sono cumulabili tra di loro. In fase di prenotazione è obbligatorio
                            indicare a quale promozione si vuole aderire.</i>
                        </p>

                    </Grid>
                </Grid>
            </div>
            <br/>
            <Coupon onChange={onEventChange('step2Coupon')} value={step2Coupon}/>
        </React.Fragment>
    )
}

StepBonusInpsieme.propTypes = {
    inpsServices: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.any.isRequired,
            label: PropTypes.string.isRequired,
        })
    ),
    participations: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.any.isRequired,
            label: PropTypes.string.isRequired,
        })
    ),
    step2bonuses: PropTypes.arrayOf(
        PropTypes.shape({
            bonustype: PropTypes.string.isRequired,
        })
    ),
    step2Participation: PropTypes.string,
    step2InpsServiceId: PropTypes.string,
    step2Grade: PropTypes.string,
    step2InpsServiceFlag: PropTypes.bool,
    step2BonusRequested: PropTypes.bool,
    step2BonusSurname: PropTypes.string,
    step2BonusName: PropTypes.string,
    step2Coupon: PropTypes.string,
    onEventChange: PropTypes.func.isRequired,
    onSimpleCheckboxChange: PropTypes.func.isRequired,
    onBonusRequest: PropTypes.func.isRequired,
}
