import React from 'react'
import PropTypes from 'prop-types'
import {makeStyles} from '@material-ui/core/styles'
import clsx from 'clsx'
import Grid from '@material-ui/core/Grid'
import StepBonusInpsieme from './StepBonusInpsieme'
import StepBonusPrivate from './StepBonusPrivate'
import {participations, participationsLookup} from '../../specificUtils/configs'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
}))

export default function StepBonus(props) {
  const classes = useStyles()
  const {
    state,
    onEventChange,
    onSimpleCheckboxChange,
    onBonusRequest,
    onCheckboxChange,
  } = props
  const {
    division,
    step2Participation,
    step2InpsServiceId,
    inpsServices,
    step2Grade,
    step2bonuses,
    step2BonusRequested,
    step2BonusName,
    step2BonusSurname,
    step2BonusType,
    step2WelfareType,
    step2AziendaConvenzionataNote,
    step2InpsServiceFlag,
    step2Coupon,
    errors,
    variablePracticeInpsNumber
  } = state

  return (
    <Grid container className={clsx(classes.root, 'step2-inner')} spacing={2}>
      <Grid item xs={12}>
        {/* Revisione form ADA come da richiesta del 22.02.23 di Gloria
        Trasformare il type degli utenti INPSNuovi in INPS
        Aggiunta logica che prevede un OR alla verifica del type utente
        */ }
        { step2Participation === participationsLookup.INPSIEME ||
        step2Participation === participationsLookup.INPSNUOVO ? (
          <StepBonusInpsieme
            division={division}
            inpsServices={inpsServices}
            participations={participations}
            step2Participation={step2Participation}
            step2InpsServiceId={step2InpsServiceId}
            step2InpsServiceFlag={step2InpsServiceFlag}
            step2BonusRequested={step2BonusRequested}
            step2BonusType={step2BonusType}
            step2BonusName={step2BonusName}
            onEventChange={onEventChange}
            variablePracticeInpsNumber={variablePracticeInpsNumber}
            onSimpleCheckboxChange={onSimpleCheckboxChange}
            onBonusRequest={onBonusRequest}
            step2Coupon={step2Coupon}
            errors={errors}
          />
        ) : (
          step2Participation === participationsLookup.PRIVATO && (
            <StepBonusPrivate
              division={division}
              step2BonusRequested={step2BonusRequested}
              step2BonusType={step2BonusType}
              step2BonusName={step2BonusName}
              onBonusRequest={onBonusRequest}
              onEventChange={onEventChange}
              step2Coupon={step2Coupon}
              errors={errors}
            />
          )
        )}
      </Grid>
    </Grid>
  )
}

StepBonus.propTypes = {
  state: PropTypes.object.isRequired,
  onEventChange: PropTypes.func.isRequired,
  onSimpleCheckboxChange: PropTypes.func.isRequired,
  onBonusRequest: PropTypes.func.isRequired,
  onCheckboxChange: PropTypes.func.isRequired,
}
