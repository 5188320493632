import React from 'react'
import PropTypes from 'prop-types'
import {makeStyles} from '@material-ui/core/styles'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import Grid from '@material-ui/core/Grid'
import {FormHelperText} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  dense: {
    marginTop: theme.spacing(2),
  },
  formControl: {
    marginBottom: 16,
    maxWidth: '100%',
  },
  // menu: {
  //   width: 400,
  // },
}))

const disabilityTypes = {
  comma1: 'giovane disabile ai sensi della legge 104/92 art. 3 comma 1',
  comma3:
    'giovane disabile ai sensi della legge 104/92 art. 3 comma 3 o giovane con invalidità civile al 100% o equiparato senza diritto all’indennità di accompagnamento',
  civile:
    'giovane con invalidità civile al 100 % o equiparato con diritto all’indennità di accompagnamento',
}

const disabilityHelperType = {
  assistenza:
    'assistenza aggiuntiva resa da Giocamondo NON nel rapporto 1:1 (nessun costo aggiuntivo per la famiglia)',
  h1: 'un accompagnatore',
  h2: 'due accompagnatori',
  h3: 'tre accompagnatori',
}

const disabilityHelper = {
  parente: 'parenti maggiorenni entro il III grado',
  esterno: 'operatore preferibilmente segnalato dalla famiglia',
}

export default function Disability(props) {
  const classes = useStyles()
  const {
    step3Handicap,
    step3DisabilitiesType,
    step3DisabilitiesHelperType,
    step3DisabilitiesHelper,
    step3DisabilitiesHelperSameRoom,
    step3DisabilitiesNotes,
    onFlagChange,
    onChange,
    errors,
  } = props

  const updateType = event => {
    const {target} = event

    onChange('step3DisabilitiesType')(event)

    onChange('step3DisabilitiesHelperType')({
      target: {
        value: target.value === 'comma1' ? 'assistenza' : '',
      },
    })

    if (target.value === 'comma1') {
      onChange('step3DisabilitiesHelper')({
        target: {
          value: '',
        },
      })
    }
  }

  const updateHelperType = event => {
    onChange('step3DisabilitiesHelperType')(event)
  }

  const updateHelper = event => {
    onChange('step3DisabilitiesHelper')(event)
  }

  const updateNotes = event => {
    onChange('step3DisabilitiesNotes')(event)
  }

  const updateHelperSameRoom = event => {
    onChange('step3DisabilitiesHelperSameRoom')(event)
  }

  return (
    <div className="box-light">
      <Grid container className={classes.dense} spacing={2}>
        <Grid item md={12} sm={12} xs={12}>
          <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel component="legend">
              Il partecipante è un giovane con disabilità?
            </FormLabel>
            <RadioGroup
              className={classes.group}
              value={step3Handicap}
              onChange={onFlagChange('step3Handicap')}>
              <FormControlLabel value="si" control={<Radio />} label="Sì" />
              <FormControlLabel value="no" control={<Radio />} label="No" />
            </RadioGroup>
            <br />
            Dichiaro che la situazione indicata è la stessa inserita in
            domanda INPS
          </FormControl>
        </Grid>

        {step3Handicap === 'si' && (
          <Grid item md={12} sm={12} xs={12}>
            <FormControl component="fieldset" className={classes.formControl}>
              <FormLabel component="legend">
                Si prega di indicare in maniera precisa la tipologia di
                disabilità riportata nella vostra area riservata INPS a scelta
                tra le seguenti:*
              </FormLabel>
              <TextField
                id="disability-type"
                select
                className={classes.select}
                value={step3DisabilitiesType}
                onChange={updateType}
                SelectProps={{
                  MenuProps: {
                    className: classes.menu,
                  },
                }}
                margin="normal">
                {Object.entries(disabilityTypes).map(([key, item], index) => (
                  <MenuItem key={index} value={key}>
                    {index + 1}) {item}
                  </MenuItem>
                ))}
              </TextField>
              {errors && errors.step3DisabilitiesType && (
                <span className="text-warning">Campo obbligatorio</span>
              )}
            </FormControl>
          </Grid>
        )}

        {step3DisabilitiesType && (
          <Grid item md={12} sm={12} xs={12}>
            <FormControl component="fieldset" className={classes.formControl}>
              <FormLabel component="legend">
                Per una buona riuscita del soggiorno, consigliamo di usufruire
                dell'assistenza così come attribuita da INPS per cui il numero
                degli accompagnatori assegnati è il seguente:
              </FormLabel>

              <TextField
                id="disability-helper-type"
                select
                className={classes.select}
                value={step3DisabilitiesHelperType}
                onChange={updateHelperType}
                SelectProps={{
                  MenuProps: {
                    className: classes.menu,
                  },
                }}
                margin="normal">
                {step3DisabilitiesType === 'comma1' && (
                  <MenuItem value="assistenza">
                    {disabilityHelperType.assistenza}
                  </MenuItem>
                )}
                {step3DisabilitiesType === 'comma3' && [
                  <MenuItem key="h1" value="h1">
                    {disabilityHelperType.h1}
                  </MenuItem>,
                  <MenuItem key="h2" value="h2">
                    {disabilityHelperType.h2}
                  </MenuItem>,
                ]}
                {step3DisabilitiesType === 'civile' && [
                  <MenuItem key="h2" value="h2">
                    {disabilityHelperType.h2}
                  </MenuItem>,
                  <MenuItem key="h3" value="h3">
                    {disabilityHelperType.h3}
                  </MenuItem>,
                ]}
              </TextField>

              {errors && errors.step3DisabilitiesHelperType && (
                <span className="text-warning">Campo obbligatorio</span>
              )}
            </FormControl>
          </Grid>
        )}

        {step3DisabilitiesType && step3DisabilitiesType !== 'comma1' && (
          <Grid item md={12} sm={12} xs={12}>
            <FormControl component="fieldset" className={classes.formControl}>
              <FormLabel component="legend">Tipo di accompagnatori</FormLabel>
              <TextField
                id="disability-type"
                select
                className={classes.select}
                value={step3DisabilitiesHelper}
                onChange={updateHelper}
                SelectProps={{
                  MenuProps: {
                    className: classes.menu,
                  },
                }}
                margin="normal">
                {Object.entries(disabilityHelper).map(([key, item], index) => (
                  <MenuItem key={index} value={key}>
                    {index + 1}) {item}
                  </MenuItem>
                ))}
              </TextField>

              {errors && errors.step3DisabilitiesHelper && (
                <span className="text-warning">Campo obbligatorio</span>
              )}
            </FormControl>
          </Grid>
        )}

        {step3Handicap === 'si' && (
          <Grid item md={12} sm={12} xs={12}>
            <FormControl component="fieldset" className={classes.formControl}>
              <FormLabel component="legend">
                Indicare sinteticamente dettagli da comunicarci o la richiesta
                di portare con sé ausili fisici (esempio carrozzina, tutori ecc)
              </FormLabel>
              <TextField
                id="disability-notes"
                multiline
                rowsMax="4"
                value={step3DisabilitiesNotes}
                className={classes.textField8}
                margin="normal"
                onChange={updateNotes}
              />
            </FormControl>
          </Grid>
        )}

        {step3DisabilitiesType && step3DisabilitiesType !== 'comma1' && (
          <Grid item md={12} sm={12} xs={12}>
            <FormControl component="fieldset" className={classes.formControl}>
              <FormLabel component="legend">
                Indicare se l’accompagnatore/accompagnatori (parente/i o assistente/i Giocamondo)
                preferibilmente deve alloggiare nella stessa stanza del giovane disabile.
                Precisiamo che non tutte le strutture dispongono di camere doppie:
              </FormLabel>
              <RadioGroup
                className={classes.group}
                value={step3DisabilitiesHelperSameRoom}
                onChange={updateHelperSameRoom}>
                <FormControlLabel
                  value="si"
                  control={<Radio />}
                  label="Sì, stessa stanza"
                />
                <FormControlLabel
                  value="no"
                  control={<Radio />}
                  label="No, il giovane disabile può alloggiare con altri partecipanti"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        )}
      </Grid>
    </div>
  )
}

Disability.propTypes = {
  step3Handicap: PropTypes.oneOf(['si', 'no', '']),
  step3Helper: PropTypes.oneOf(['si', 'no', '']),
  step3Disabilities: PropTypes.oneOf(['carrozzina', 'motoria', 'altro', '']),
  step3NumberHelpers: PropTypes.number,
  onFlagChange: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  step3TypeHelpers: PropTypes.string,
}
