import React, {useState} from 'react'
import PropTypes from 'prop-types'
// material-ui components
import clsx from 'clsx'
import {makeStyles} from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
// internal components
import BirthPlaceInput from './BirthPlaceInput'
import BirthPlaceForeignInput from './BirthPlaceForeignInput'
import useApi from '../hooks/useApi'
import PasswordComponent from "./Membership/MembershipPasswordComponent";

const nationalities = {
    ITALIANA: 'Italiana',
    ESTERA: 'Estera',
}

const useStyles = makeStyles(theme => ({
    menu: {
        width: 200,
    },
    errorMessage: {
        color: 'red',
    },
    button: {
        // margin: theme.spacing(1),
        minWidth: 120,
    },
    nationSelector: {
        minWidth: 200,
    },
}))

function Anagrafica(props) {
    const classes = useStyles()
    const {getFiscalCode} = useApi()
    const [password, setPassword] = useState('')
    const [repeatedPassword, setRepeatedPassword] = useState('')
    const [showPassword, setShowPassword] = useState(false)
    const {
        // surname
        surname,
        surnameError,
        handleSurnameChange,
        // name
        name,
        nameError,
        handleNameChange,
        // gender
        gender,
        genderError,
        handleGenderChange,
        // nationality
        nationality,
        nationalityError,
        handleNationalityChange,
        // birthplace
        birthCity,
        birthCityError,
        handleBirthCityChange,
        birthProvince,
        birthProvinceError,
        handleBirthProvinceChange,
        // foreign birthplace
        birthNation,
        birthNationError,
        handleBirthNationChange,
        foreignBirthCity,
        foreignBirthCityError,
        handleForeignBirthCityChange,
        // birthdate
        birthDate,
        birthDateError,
        handleBirthDateChange,
        // fiscal code
        fiscalCode,
        fiscalCodeError,
        handleFiscalCodeChange,
        fiscalCodeDesc,
        disableFiscalCode = false,
        // email
        email,
        emailError,
        handleEmailChange,
        emailRequired = false,
        // phone
        phone,
        phoneError,
        handlePhoneChange,
        phoneRequired = false,
        // cell phone
        cellPhone,
        cellPhoneError,
        handleCellPhoneChange,
        cellPhoneRequired = false,
        cellPhoneDesc = '',
        emailDesc = '',
        passwords = false,
        size = "medium",
        onChangePassword
    } = props


    const handleClickShowPassword = () => {
        setShowPassword(showPassword => !showPassword)
    }

    const handleChangePassword = () => {
        if (password !== repeatedPassword) {
            onChangePassword(password);
        }
    }

    const calculateFiscalCode = async () => {
        if (birthDate && surname && name && birthCity && gender) {
            const body = {
                firstName: name,
                lastName: surname,
                gender: gender,
                birthDate: birthDate,
                cadastralCode: birthCity.cadastralCode,
            }
            const fiscalCode = await getFiscalCode(body)
            if (fiscalCode.status) {
                handleFiscalCodeChange(fiscalCode.result)
            }
        }
    }

    return (
        <React.Fragment>
            <Grid container spacing={props.spacing || 2} alignItems="flex-start">
                {/* Surname */}
                <Grid item xs={12} sm={6} md={4}>
                    <TextField
                        size={size}
                        label="Cognome *"
                        // className={classes.textField4 + ' col-xs-12'}
                        onChange={evt => handleSurnameChange(evt.target.value)}
                        value={surname}
                        error={!!surnameError}
                        margin="normal"
                        fullWidth
                        helperText={!!surnameError && 'Cognome richiesto'}
                    />
                </Grid>

                {/* Name */}
                <Grid item xs={12} sm={6} md={4}>
                    <TextField
                        size={size}
                        label="Nome *"
                        // className={classes.textField4 + ' col-xs-12'}
                        onChange={evt => handleNameChange(evt.target.value)}
                        error={!!nameError}
                        value={name}
                        helperText={!!nameError && 'Nome richiesto'}
                        margin="normal"
                        fullWidth
                    />
                </Grid>

                {/* Gender */}
                <Grid item xs={12} sm={12} md={4}>
                    <FormControl
                        component="fieldset"
                        className="inline-block radio-inline">
                        <FormLabel component="legend"/>
                        <RadioGroup
                            aria-label="Sesso"
                            name="gender1"
                            value={gender}
                            error={genderError}
                            onChange={evt => handleGenderChange(evt.target.value)}>
                            <FormControlLabel value="F" control={<Radio/>} label="Donna"/>
                            <FormControlLabel value="M" control={<Radio/>} label="Uomo"/>
                        </RadioGroup>
                        {!!genderError && (
                            <FormHelperText className={classes.errorMessage}>
                                richiesto*
                            </FormHelperText>
                        )}
                    </FormControl>
                </Grid>

                {/* Nationality */}
                <Grid item xs={12} sm={6} md={6}>
                    <TextField
                        size={size}
                        select
                        // className={
                        //   classes.textField4 + 'select col-xs-6 lateral-margin-8px'
                        // }
                        className={clsx('select', classes.nationSelector)}
                        value={nationality}
                        error={!!nationalityError}
                        onChange={evt => handleNationalityChange(evt.target.value)}
                        SelectProps={{
                            native: true,
                            MenuProps: {
                                className: classes.menu,
                            },
                        }}
                        helperText={
                            !!nationalityError
                                ? 'Nazione di nascita richiesta'
                                : 'Nazione di nascita'
                        }
                        // fullWidth
                        margin="normal">
                        {Object.values(nationalities).map(nationality => (
                            <option key={nationality} value={nationality}>
                                {nationality}
                            </option>
                        ))}
                    </TextField>
                </Grid>

                <Grid item xs={12} md={12}>
                    {nationality === nationalities.ESTERA ? (
                        <BirthPlaceForeignInput
                            birthNation={birthNation}
                            birthNationError={birthNationError}
                            handleBirthNationChange={handleBirthNationChange}
                            foreignBirthCity={foreignBirthCity}
                            foreignBirthCityError={foreignBirthCityError}
                            handleForeignBirthCityChange={handleForeignBirthCityChange}
                            birthDate={birthDate}
                            birthDateError={birthDateError}
                            handleBirthDateChange={handleBirthDateChange}
                        />
                    ) : (
                        <BirthPlaceInput
                            birthCity={birthCity}
                            birthCityError={birthCityError}
                            handleBirthCityChange={handleBirthCityChange}
                            birthProvince={birthProvince}
                            birthProvinceError={birthProvinceError}
                            handleBirthProvinceChange={handleBirthProvinceChange}
                            birthDate={birthDate}
                            birthDateError={birthDateError}
                            handleBirthDateChange={handleBirthDateChange}
                        />
                    )}
                </Grid>

                {/* Fiscal Code */}
                <Grid item xs={12} md={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={4} md={3} lg={2}>
                            <Button
                                size="small"
                                className={clsx(classes.button, 'btn-xs', 'btn-info', 'btn-cf')}
                                onClick={calculateFiscalCode}
                                color="primary"
                                disabled={
                                    disableFiscalCode ||
                                    !(birthDate && surname && name && birthCity && gender)
                                }>
                                CALCOLA CF
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={8} md={6} lg={6}>
                            <TextField
                                size={size}
                                label="Codice Fiscale *"
                                className={
                                    classes.textField6 + ' select col-xs-12 no-margin cf-field'
                                }
                                onChange={evt => handleFiscalCodeChange(evt.target.value)}
                                disabled={disableFiscalCode}
                                value={fiscalCode}
                                error={!!fiscalCodeError}
                                helperText={
                                    !!fiscalCodeError
                                        ? 'Codice fiscale richiesto'
                                        : fiscalCodeDesc || ''
                                }
                                margin="normal"
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </Grid>
                {/* <Grid item xs={12} md={6}>
        </Grid> */}

                {/* Email */}
                {handleEmailChange && <Grid item xs={12} sm={12} md={4}>
                    <TextField
                        size={size}
                        label={`Email ${emailRequired ? ' *' : ''}`}
                        value={email}
                        error={!!emailError}
                        helperText={
                            !!emailError ? ((typeof emailError === 'string') ? emailError : 'Email richiesta') : emailDesc || null
                        }
                        onChange={evt => handleEmailChange(evt.target.value)}
                        // className={classes.textField4 + ' col-xs-12'}
                        margin="normal"
                        fullWidth
                    />
                </Grid>}

                {/*Password e conferma password*/}
                {passwords && <Grid item xs={12} sm={12} md={4} style={{margin: 'auto'}}>
                    <PasswordComponent
                        componentStyle={classes.inputWrapper}
                        fullWidth
                        onChange={(pw) => {
                            setPassword(pw);
                            handleChangePassword();
                        }}
                        value={password}
                        placeholder="Password*"
                        error={
                            ((password.length > 0 &&
                                    repeatedPassword.length > 0 &&
                                    password !== repeatedPassword)
                                ||
                                (password.length > 0 && password.length < 8))
                        }
                        helperText={
                            (password.length > 0 && password.length < 8) ? 'La password deve contenere almeno 8 caratteri' :
                                (password.length > 0 &&
                                repeatedPassword.length > 0 &&
                                password !== repeatedPassword
                                    ? 'La password non corrisponde'
                                    : 'Inserisci la tua password per accedere all’Area Club')
                        }
                        showPassword={showPassword}
                        onShowPasswordClicked={handleClickShowPassword}
                    />
                </Grid>}
                {passwords && <Grid item xs={12} sm={12} md={4} style={{margin: 'auto'}}>
                    <PasswordComponent
                        componentStyle={classes.inputWrapper}
                        fullWidth
                        onChange={(pw) => {
                            setRepeatedPassword(pw);
                            handleChangePassword();
                        }}
                        value={repeatedPassword}
                        placeholder="Ripeti password*"
                        error={
                            ((password.length > 0 &&
                                    repeatedPassword.length > 0 &&
                                    password !== repeatedPassword)
                                ||
                                (repeatedPassword.length > 0 && repeatedPassword.length < 8))
                        }
                        helperText={
                            (repeatedPassword.length > 0 && repeatedPassword.length < 8) ? 'La password deve contenere almeno 8 caratteri' : (password.length > 0 &&
                            repeatedPassword.length > 0 &&
                            password !== repeatedPassword
                                ? 'La password non corrisponde'
                                : 'La registrazione all’area club è obbligatoria e necessaria per gestire la tua pratica')
                        }
                        showPassword={showPassword}
                        onShowPasswordClicked={handleClickShowPassword}
                    />
                </Grid>}
                {/* CellPhone */}
                {handleCellPhoneChange && <Grid item xs={12} sm={12} md={4}>
                    <TextField
                        size={size}
                        label={`Cellulare${cellPhoneRequired ? ' *' : ''}`}
                        value={cellPhone}
                        error={!!cellPhoneError}
                        helperText={
                            !!cellPhoneError ? ((typeof cellPhoneError === 'string') ? cellPhoneError : 'Cellulare richiesto') : cellPhoneDesc || null
                        }
                        onChange={evt => handleCellPhoneChange(evt.target.value)}
                        // className={classes.textField3}
                        margin="normal"
                        fullWidth
                    />
                </Grid>}

                {/* Phone */}
                {handlePhoneChange && <Grid item xs={12} sm={12} md={4}>
                    <TextField
                        size={size}
                        label={`Telefono${phoneRequired ? ' *' : ''}`}
                        value={phone}
                        error={!!phoneError}
                        helperText={!!phoneError && 'Telefono richiesto'}
                        onChange={evt => handlePhoneChange(evt.target.value)}
                        // className={classes.textField3}
                        margin="normal"
                        fullWidth
                    />
                </Grid>}
            </Grid>
        </React.Fragment>
    )
}

Anagrafica.propTypes = {
    // surname
    surname: PropTypes.string,
    surnameError: PropTypes.any,
    handleSurnameChange: PropTypes.func.isRequired,
    // name
    name: PropTypes.string,
    nameError: PropTypes.any,
    handleNameChange: PropTypes.func.isRequired,
    // gender
    gender: PropTypes.string,
    genderError: PropTypes.any,
    handleGenderChange: PropTypes.func.isRequired,
    // nationality
    nationality: PropTypes.string,
    nationalityError: PropTypes.any,
    handleNationalityChange: PropTypes.func.isRequired,
    // birthplace
    birthCity: PropTypes.object,
    birthCityError: PropTypes.any,
    handleBirthCityChange: PropTypes.func.isRequired,
    birthProvince: PropTypes.string,
    birthProvinceError: PropTypes.any,
    handleBirthProvinceChange: PropTypes.func.isRequired,
    // foreign birthplace
    birthNation: PropTypes.string,
    birthNationError: PropTypes.any,
    handleBirthNationChange: PropTypes.func.isRequired,
    foreignBirthCity: PropTypes.string,
    foreignBirthCityError: PropTypes.any,
    handleForeignBirthCityChange: PropTypes.func.isRequired,
    // birthdate
    birthDate: PropTypes.instanceOf(Date),
    birthDateError: PropTypes.any,
    handleBirthDateChange: PropTypes.func.isRequired,
    // fiscal code
    fiscalCode: PropTypes.string,
    fiscalCodeError: PropTypes.any,
    handleFiscalCodeChange: PropTypes.func.isRequired,
    disableFiscalCode: PropTypes.bool,
    // email
    email: PropTypes.string,
    emailError: PropTypes.any,
    handleEmailChange: PropTypes.func.isRequired,
    emailRequired: PropTypes.bool,
    // phone
    phone: PropTypes.string,
    phoneError: PropTypes.any,
    phoneRequired: PropTypes.bool,
    handlePhoneChange: PropTypes.func.isRequired,
    onChangePassword: PropTypes.func,
    // cell phone
    cellPhone: PropTypes.string,
    cellPhoneError: PropTypes.any,
    handleCellPhoneChange: PropTypes.func.isRequired,
    cellPhoneRequired: PropTypes.bool,
    passwords: PropTypes.bool,
    cellPhoneDesc: PropTypes.string,
    emailDesc: PropTypes.string,
}

export default Anagrafica
