import React, {useContext} from 'react'
import StepCustomerData from './StepCustomerData'
import StepInformation from './StepInformation'
import StepExtraServices from './StepExtraServices'
import StepBonus from './StepBonus'
import StepPayment from './StepPayment'
import {stepsByName} from '../specificUtils/configs'
import Stepper from '../../components/Stepper'
import {AppContext} from '../../contexts/AppContext'
import useContextController from '../../hooks/useContextController'
import SidebarWrapper from './SidebarWrapper'
import {dateToServerString, verifyDoubleBooking} from 'lin/specificUtils/api'
import {useDialogContext} from 'contexts/DialogContext'
import useAuthentication, {TOKEN_KEY} from "../../hooks/useAuthentication";
import DateComponent from "../../components/DateComponent";
import * as actions from "../../contexts/UserActions";
import useApi from "../../hooks/useApi";
import {Modal, useModal} from "react-async-popup";
import CustomerDataModal from "./CustomerDataModal";

// specific main
function getStepContent(activeStep, division) {
    switch (activeStep) {
        case stepsByName.CUSTOMER_DATA:
            return <StepCustomerData/>
        case stepsByName.BONUS:
            return <StepBonus/>
        case stepsByName.INFORMATION:
            return <StepInformation/>
        case stepsByName.EXTRA_SERVICES:
            return <StepExtraServices/>
        case stepsByName.RECAP:
            return <StepPayment/>
        default:
            return <SidebarWrapper/>
    }
}

export default function StepperWrapper() {
    const [showModal] = useModal({});
    const [state] = useContext(AppContext)
    const api = useApi()
    const {validateStep2Inps, validateStepDisability, validateVacationCustomerData} = useContextController()
    const dialog = useDialogContext()
    // const {signUp, checkIsUserLogged, getError} = useAuthentication()
    const steps = [
        'IL MIO SOGGIORNO',
        'BONUS',
        'INFORMAZIONI UTILI',
        'PERSONALIZZA LA TUA ESPERIENZA',
        'CONFERMA',
    ]

    const stepValidations = [
        {
            step: stepsByName.CUSTOMER_DATA,
            errorMessage: 'NO_MESSAGE',
            validateAsync: async () => {
                const step = stepValidations.find((x) => x.step === stepsByName.CUSTOMER_DATA);
                if (state.division !== '1' && state.division !== '2') {
                    return true
                }

                if (!state.step1ChildFiscalCode) {
                    return true
                }

                const isDouble = await verifyDoubleBooking(
                    state.step1ChildFiscalCode,
                    state.division,
                    state.division === '1' ? '6' : '1'
                )
                //
                // if (isDouble === false) {
                //     return true
                // }

                if (!validateVacationCustomerData()) {
                    step.errorMessage = 'Ricontrolla le informazioni inserite. Alcuni dati sono mancanti o non validi.'
                    return false
                }

                const {show} = await Modal.new({
                    title: "Sei sicuro di aver inserito i dati correttamente?",
                    content: <CustomerDataModal state={state}/>,
                    cancelText: "No, voglio modificarli",
                    okText: "Sì dati corretti, continua"
                });
                const result = await show();
                if (!result) {
                    return false;
                } else {
                    step.errorMessage = 'Ricontrolla le informazioni inserite. Alcuni dati sono mancanti o non validi.'
                }

                //Effettuo la registrazione e, se va a buon fine, può andare allo step successivo.
                //La registrazione viene effettuata solo se l'utente non è loggato e il suo CF non esiste come account
                // if ((!(await checkIsUserLogged())) && !state.memberExists) {
                //     const signUpResult = await memberSignUpHandler();
                //     if (!signUpResult) {
                //         return false
                //     }
                // }

                /**
                 * Rimosso alert per check prenotazioni doppie
                 * Feb 2025
                
                if (isDouble) {
                    if (state.division === '1') {
                        await dialog.openDialog(
                            'Codice fiscale del partecipante già presente in una prenotazione attiva',
                            `Attenzione il codice fiscale del partecipante risulta già associato ad un'altra prenotazione attiva. Nel caso in cui si proseguisse con la prenotazione, la precedente verrà annullata e risulterà valida l’ultima completata temporalmente.`
                        )
                        // return false;
                    } else if (state.division === '2') {
                        await dialog.openDialog(
                            'Codice fiscale del partecipante già presente in una prenotazione attiva',
                            `Attenzione il codice fiscale del partecipante risulta già associato ad un'altra prenotazione attiva. Nel caso in cui si proseguisse con la prenotazione, la precedente verrà annullata e risulterà valida l’ultima completata temporalmente.`
                        )
                    }
                }
                */

                return true;
            },
        },
        {
            step: 2,
            validateAsync: async () => {
                if (state.step3Handicap !== 'si') {
                    return true
                }

                if (validateStepDisability() === false) {
                    return false
                }

                if (state.division === '1') {
                    await dialog.openDialog(
                        'Informazioni',
                        <span>
              In seguito alla prenotazione, il nostro ufficio Giocamondo vi contatterà per verificare le esigenze relative alla richiesta di assistenza e definire gli eventuali importi corrispondenti.
            </span>
                    )
                } else if (state.division === '2') {
                    await dialog.openDialog(
                        'Informazioni',
                        <span>
            In seguito alla prenotazione, il nostro ufficio Giocamondo vi contatterà per verificare le esigenze relative alla richiesta di assistenza e definire gli eventuali importi corrispondenti.
                            {/*
              al termine della prenotazione troverete l’indicazione di un
              importo che tiene conto solo della partecipazione del giovane
              disabile: successivamente riceverete il contratto{' '}
              <u>non vincolante</u> che tenga conto anche della presenza di
              assistenti e del costo relativo.
          */}
          </span>
                    )
                }

                return true
            },
        },
    ]

    return (
        <Stepper
            steps={steps}
            customerCareDescription={[
                'Per assistenza',
                'dal Lun al Ven: 8:30 - 17:30',
                //'dal Lun a Dom: 8:00 - 20:00',
            ]}
            customerCarePhone="0736.343440 - 0736.336339"
            getStepContent={() => getStepContent(state.activeStep, state.division)}
            stepValidations={stepValidations}
            stepsByName={stepsByName}
            Sidebar={SidebarWrapper}
            canExpectPracticeData
            sendBookingButtonLabel="Vai alla conferma"
        />
    )
}
